.card {
    position: relative;
    margin: 0;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    border-radius: 6px;
    padding: 0.5rem;
    overflow: hidden;
    background: white;
}

#container-second.active {
    padding-left: 24%;
}

.container-second {
    transition: 0.4s;
    padding-left: 6%;
}

.items {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
}

.item {
    background: #f1f1f1;
    margin-top: 10px;
    margin-right: 10px;
}

.item-header {
    color: white;
    position: relative;
    margin-bottom: 25px;
}

.title {
    font-size: 30px;
    display: flex;
    align-items: center;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    border-radius: 4px;
    justify-content: center;
    padding: 5px 0px 20px 0px;
}

.price {
    position: absolute;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    border-radius: 20px;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
    width: 80%;
    max-width: 360px;
    text-align: center;
    padding: 8px 0;
}

.title span {
    font-size: 30px;
}

.item-sidebar a,
.border {
    width: 80px;
    height: 1px;
    background: #ddd;
    margin: auto;
}

.item-list li {
    margin: 6px;
    font-size: 15px;
    text-align: center;
}

.item .item-header {
    background-image: linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%);
}

.item .price {
    background-image: linear-gradient(to right, #fa709a 0%, #fee140 100%);
}

@media screen and (max-width: 600px) {
    .container-second {
        padding-top: 10%;
        padding-left: 12%;
    }
}
